<template>
  <div class="contact">
    <img src="../assets/contact/BG.png" class="ba" />
    <div class="title-j">
      <!-- <dr-tag enName="contact US" title="联系我们" color="white" opacity="1"/> -->
      <img src="../assets/contact/gg.png" class="png" />
    </div>
    <div class="content">
      <div
        class="left"
        @mouseenter="mouseenter('l')"
        @mouseleave="mouseleave('l')"
      >
        <div class="try">
          <div class="img">
            <img src="../assets/contact/1.png" class="ttt" />
          </div>
          <div v-for="(value, key, index) in left" :key="index" class="item">
            <div class="key">{{ key }}</div>
            <div>{{ value }}</div>
          </div>
        </div>

        <transition name="title">
          <img v-if="showl" src="../assets/contact/left.png" class="item-ba" />
        </transition>
      </div>
      <div
        class="middle"
        @mouseenter="mouseenter('m')"
        @mouseleave="mouseleave('m')"
      >
        <div class="try">
          <div class="img">
            <img src="../assets/contact/2.png" class="hhhhh" />
          </div>
          <div v-for="(value, key, index) in middle" :key="index" class="item">
            <div class="key">{{ key }}</div>
            <div>{{ value }}</div>
          </div>
        </div>
        <transition name="title">
          <img
            v-if="showm"
            src="../assets/contact/middle.png"
            class="item-ba"
          />
        </transition>
      </div>
      <div
        class="right"
        @mouseenter="mouseenter('r')"
        @mouseleave="mouseleave('r')"
      >
        <div class="try">
          <div class="img">
            <img src="../assets/contact/3.png" class="ggggg" />
          </div>
          <div v-for="(value, key, index) in right" :key="index" class="item">
            <div class="key">{{ key }}</div>
            <div>{{ value }}</div>
          </div>
        </div>
        <transition name="title">
          <img v-if="showr" src="../assets/contact/right.png" class="item-ba" />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
console.log('aaaa');
// import DrTag from "../components/DrTag/index.vue";
export default {
  components: {
    // DrTag,
  },
  data() {
    return {
      left: {
        // 联系电话: "020-80922697",
        电子邮箱: "bussiness@aoshugame.com",
      },
      middle: {
        电子邮箱: "zhaopin@aoshugame.com",
      },
      right: {
        商务联系人: "黄女士 Lacey",
        // 联系邮箱: "zhaopin@xinyuanstu.com",
        联系邮箱: "huanglishi@aoshugame.com",
      },
      showl: false,
      showm: false,
      showr: false,
    };
  },
  mounted() {
    window.scrollTo({
      //滚动到元素位置

      top: 0,
      //top: 400//滚动到指定距离
      //top: 0,//滚动到顶部
      //top: document.documentElement.scrollHeight,//滚动到底部
      behavior: "smooth", // 平滑滚动
    });
  },
  methods: {
    mouseenter(index) {
      switch (index) {
        case "l":
          this.showl = true;
          break;
        case "m":
          this.showm = true;
          break;
        case "r":
          this.showr = true;
          break;
      }
    },
    mouseleave(index) {
      switch (index) {
        case "l":
          this.showl = false;
          break;
        case "m":
          this.showm = false;
          break;
        case "r":
          this.showr = false;
          break;
      }
    },
  },
};
</script>
<style scoped>
.try {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.ggggg{
  width: 182px;
}

.hhhhh{
  width: 166px;
}

.ttt{
  width: 181px;
}

.png{
  width: 369px;
}
.contact {
  height: 904px;
  /* background: black; */
  display: flex;
  justify-content: flex-start;

  align-items: center;
  flex-direction: column;
  /* align-items: center; */
  position: relative;
}
.conta {
  height: 46px;
  width: auto;
  margin-bottom: 126px;
}
.item-ba {
  /* width: 100%; */
  position: absolute;
  z-index: -1;
  display: block;
  left: 0;
  top: 0;
  height: 400px;
  z-index: 1;
  width: auto;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-name: breathe;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate; */
}

/* @-webkit-keyframes breathe {
  0% {
    opacity: 0.4;
    box-shadow: 0 1px 2px rgba(0, 147, 223, 0.4),
      0 1px 1px rgba(0, 147, 223, 0.1) inset;
  }

  100% {
    opacity: 1;
    border: 1px solid rgba(59, 235, 235, 0.7);
    box-shadow: 0 1px 30px #0093df, 0 1px 20px #0093df inset;
  }
} */

.title-j {
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: 130px;
  margin-bottom: 126px;
}
.ba {
  width: 100%;
  height: 904px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.content {
  display: flex;
  z-index: 2;
}

.header {
  font-size: 31px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 29px;
  text-align: center;
}
.item {
  font-size: 18px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #f5f5f7;
  opacity: 0.75;
  margin-bottom: 29px;
  text-align: center;
}
.key {
  margin-bottom: 10px;
}
.img {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 29px;
}

.left {
  width: 495.5px;
  position: relative;
  /* overflow:hidden; zoom:1; */
}
.middle {
  width: 473px;
  position: relative;
  margin: 0 -20px;
}
.right {
  width: 495.5px;
  position: relative;
}
</style>